/*
Dit is de userLink Dialog component. Op deze pagina kan een gebruiker/beheerder een gebruiker zoeken/selecteren om aan een versie te koppelen. 
*/
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Search } from "@mui/icons-material";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper as MuiPaper,
    Slide,
    TextField,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import styled from "@emotion/styled";

import { colorRed, secondaryColor } from "../../constants/colors";
import useDebounce from "../../hooks/useDebounce";
import { VERSION_PATHS } from "../../routes/paths/versions";
import { attachUserToVersion } from "../../store/actions";
import { selectAllUsers, selectAllUsersWithoutEvoItUsers, selectAllVersionsByVersionId } from "../../store/selectors";
import { isCurrentUserAnEvoItUser } from "../../utils/helpers";

const Paper = styled(MuiPaper)(spacing);

// functie om dialog sleepbaar te maken
const PaperComponent = (enhancedProps) => {
    const myRef = useRef();
    const realProps = { ...enhancedProps };
    delete realProps.setOffsetY;
    const [y, setY] = useState();
    const debouncedValue = useDebounce(y, 250);

    //zorgt ervoor dat de y wordt bepaald als er wordt gedragged
    useEffect(() => {
        enhancedProps?.setOffsetY && enhancedProps.setOffsetY(debouncedValue);
    }, [debouncedValue]);

    // zorgt ervoor dat de y wordt bepaald als de dialoog opent
    useEffect(() => {
        const y = myRef.current?.getBoundingClientRect().y;
        if (y) {
            setY(y);
        }
    }, [myRef.current?.getBoundingClientRect()]);

    return (
        <Draggable
            handle={`${"#" + realProps?.children?.props?.children[0]?.props?.id}`}
            cancel={'[class*="MuiDialogContent-root"]'}
            onDrag={(e) => setY(e.y)}
            nodeRef={myRef}
        >
            <Paper ref={myRef} {...realProps} />
        </Draggable>
    );
};

// functie om de dialoog met transitie te open van beneden naar boven en bij het afsluiten van boven naar beneden
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UserVersionDialog({ attachUserToVersion }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { versionId } = useParams();
    const [userFilter, setUserFilter] = useState([]);
    const version = useSelector((state) => selectAllVersionsByVersionId(state, Number(versionId)));
    const isEvoItUser = isCurrentUserAnEvoItUser();
    const users = useSelector((state) =>
        isEvoItUser ? selectAllUsers(state) : selectAllUsersWithoutEvoItUsers(state),
    );
    const defaultUsers = users?.filter((u) => {
        return !version?.userlicapplinks?.some((user) => user.usersid === u.usersid);
    });

    const handleClose = () => {
        //redirect naar de versies
        navigate(VERSION_PATHS.DEFAULT_PATH);
    };

    const handleListItemClick = (id) => {
        // voeg gebruiker aan versie toe
        attachUserToVersion(
            {
                userlicapplinkid: 0,
                usersid: id,
                licappsid: Number(versionId),
                profilesid: 0,
            },
            version.defappsid,
        );
        // sluit de dialoog
        handleClose();
    };

    const handleFilter = (newFilter) => {
        if (newFilter !== "") {
            setUserFilter(defaultUsers.filter((user) => user.fullName.toLowerCase().includes(newFilter.toLowerCase())));
        } else {
            setUserFilter(defaultUsers);
        }
    };

    useEffect(() => {
        if (users.length !== 0 && version) {
            setUserFilter(defaultUsers);
        }
    }, [users, version]);

    return (
        <Dialog
            name={"userlinkDialog"}
            onClose={handleClose}
            fullWidth
            keepMounted
            PaperComponent={PaperComponent}
            TransitionComponent={Transition}
            aria-labelledby="usersVersionDialog"
            id="usersVersionDialog"
            open={true}
            display="block"
            style={{
                maxHeight: "50vh",
                minHeight: "80vh",
                minwidth: "100%",
                marginTop: "auto",
                marginBottom: "auto",
            }}
        >
            <DialogTitle
                id="usersVersionDialog"
                style={{
                    backgroundColor: secondaryColor,
                    color: "white",
                    cursor: "move",
                }}
            >
                {version?.description} - {t("versionComponent.dialog.dialogTitle")}
            </DialogTitle>

            <DialogContent dividers>
                {defaultUsers.length > 7 && (
                    <TextField
                        autoFocus
                        onChange={(e) => {
                            handleFilter(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}

                <List>
                    {defaultUsers.length === 0 ? (
                        <Typography variant="inherit">{t("versionComponent.dialog.nullUsers")}</Typography>
                    ) : userFilter.length !== 0 ? (
                        userFilter?.map((user) => (
                            <ListItemButton
                                name={user.fullName}
                                onClick={() => handleListItemClick(user.usersid)}
                                key={`${user.usersid}`}
                            >
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: "#FFDF00", color: "#A70C00" }}>
                                        {user.fullName.charAt(0).toUpperCase()}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={user.fullName + " - " + user.loginemail} />
                            </ListItemButton>
                        ))
                    ) : (
                        <Typography variant="inherit">{t("versionComponent.dialog.userNotFound")}</Typography>
                    )}
                    {userFilter.length < 6 && defaultUsers.map((usr) => <ListItem key={`${usr.usersid}`}> </ListItem>)}
                    {userFilter.length === 0 && defaultUsers.map((usr) => <ListItem key={`${usr.usersid}`}></ListItem>)}
                </List>
            </DialogContent>
            <DialogActions>
                <Button name={"cancelButton"} onClick={handleClose} style={{ color: colorRed }}>
                    {t("versionComponent.dialog.dialogAction")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
const mapDispatchToProps = {
    attachUserToVersion,
};
export default connect(null, mapDispatchToProps)(UserVersionDialog);
