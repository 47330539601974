import { createSelector } from "redux-orm";

import scheme from "../../store/models/orm";
import { selectAllUsers } from "./userSelector";

// standaard versies
export const selectAllVersions = createSelector(scheme.Versions);

export const selectAllVersionsByVersionId = createSelector(
    scheme,
    (state, _versionId) => selectAllVersions(state),
    (_state, versionId) => versionId,
    (_state, versions, versionId) => {
        return versions?.find((v) => v.licappsid === versionId);
    },
);

// gefilterde versie
export const selectAllVersionsByDefappsId = createSelector(
    scheme,
    (state, _currentDefappsId) => selectAllUsers(state),
    (_state, currentDefappsId) => currentDefappsId,
    (orm, users, currentDefappsId) => {
        return orm.Versions?.all()
            ?.toRefArray()
            ?.filter((v) => v.defappsid === currentDefappsId)
            ?.map((v) => ({
                licappsid: v.licappsid,
                code: v.code,
                description: v.description,
                linkedUsersAmount: v.userlicapplinks.length,
                linkedUsers: v.userlicapplinks
                    ?.map((user) => ({
                        ...user,
                        ...users.find((u) => u.usersid === user.usersid),
                    }))
                    .sort((a, b) => {
                        if (a.firstname.toUpperCase() < b.firstname.toUpperCase()) {
                            return -1;
                        }
                        if (a.firstname.toUpperCase() > b.firstname.toUpperCase()) {
                            return 1;
                        }
                        return 0;
                    }),
                licversionsid: v.licversionsid,
            }))
            ?.sort((a, b) => {
                if (a.description.toUpperCase() < b.description.toUpperCase()) {
                    return -1;
                }
                if (a.description.toUpperCase() > b.description.toUpperCase()) {
                    return 1;
                }
                return 0;
            });
    },
);
export const selectAllVersionsByDefappsIdWithoutEvoItUsers = createSelector(
    scheme,
    (state, currentDefappsId) => selectAllVersionsByDefappsId(state, currentDefappsId),
    (_state, currentDefappsId) => currentDefappsId,
    (_state, versions, _currentDefappsId) => {
        return versions.map((v) => {
            const users = v.linkedUsers.filter((u) => !u.evoit);
            return {
                ...v,
                linkedUsers: users,
                linkedUsersAmount: users.length,
            };
        });
    },
);
export const amountLicenseUsers = createSelector(scheme.AmountLicenseUsers);
