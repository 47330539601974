import { groupBy } from "lodash";
import { createSelector } from "redux-orm";

import scheme from "../../store/models/orm";

// alle profielensettings groepeerd bij profileID
export const selectProfileSettingsByProfilesId = createSelector(scheme, (orm) => {
    const authGroups =
        orm.ProfileSettings.all()
            .toRefArray()
            .flatMap((item) => item.defAuthGroups) ?? [];

    // sorteer de settings obv omschrijving
    const sortedAuthGroups = authGroups.map((gr) => {
        gr.ProfileAuths.sort((a, b) => {
            return a.authdescr.toLowerCase().localeCompare(b.authdescr.toLowerCase());
        });

        return gr;
    });

    return groupBy(sortedAuthGroups, (item) => item.ProfileAuths?.[0]?.profilesid);
});

// profiels defauthlevels --> waarde van de RDO, NOA, MDF opties
export const selectAllProfileSettings = createSelector(scheme, (orm) => {
    return orm.ProfileSettings.all()
        .toRefArray()
        .map((item) => item.defAuthLevels)?.[0];
});
