/*
Dit is de create component. Op deze pagina kan een gebruiker/beheerder een nieuw profiel maken. Om te zorgen dat een gebruiker/beheerder
niet een leeg profiel kan toevoegen, wordt er een controle gedaan op de omschrijving. Ook is het mogelijk om een default profiel settings te kiezen als uitgnagspunt.
*/
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { nanoid } from "@reduxjs/toolkit";
import { useFormik } from "formik";

import CustomDialog from "../../../components/CustomDialog";
import { PROFILE_PATHS } from "../../../routes/paths/profiles";
import { useAuth } from "../../../services/auth/AuthProvider";
import { createProfile } from "../../../store/actions";
import { getProfileValidationScheme } from "../../../validation/profiles";
import Content from "./Content";

function Create({ createProfile }) {
    const { auth } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            code: "",
            description: "",
            selectedValue: "MDF",
        },
        validationSchema: getProfileValidationScheme(),
        onSubmit: () => {
            handleSubmit();
        },
    });

    //sluit nieuw profiel dialoog
    const handleClose = () => {
        navigate(PROFILE_PATHS.DEFAULT_PATH);
    };

    //voeg nieuw profiel toe aan de database
    const handleSubmit = () => {
        createProfile({
            profilesid: 0,
            code: `${formik.values.description.substring(0, 3).toUpperCase()}-${nanoid()}`,
            description: formik.values.description,
            defappsid: Number(auth.selectedTab),
            stddefauthlevelscode: formik.values.selectedValue,
        });
        handleClose();
    };

    return (
        <CustomDialog
            title={t("profileComponent.dialog.dialogTitleC")}
            id="nieuwProfielDialoog"
            draggable={true}
            animated={true}
            maxWidth="sm"
            closeWithIcon={true}
            fullWidth={true}
            open={true}
            handleClose={handleClose}
            handleSubmit={formik.submitForm}
            defaultButtons={[
                {
                    label: t("profileComponent.dialog.dialogActionC"),
                    isPrimary: false,
                    isSubmit: false,
                },
                {
                    label: t("userComponent.dialog.dialogActionA"),
                    isPrimary: true,
                    isSubmit: true,
                },
            ]}
            content={<Content formik={formik} />}
        />
    );
}
const mapDispatchToProps = {
    createProfile,
};
export default connect(null, mapDispatchToProps)(Create);
