import { t } from "i18next";

import { ERROR, UPSERT_AUTHLICENSES } from "../../../constants/redux";
import apiService from "../../../services/api/apiService";
import { handleErrors } from "../../../utils/helpers";
import { getApps } from "./apps";
import { getLicenseVersions } from "./versions";

// Get licenses
export const getLicenses = () => async (dispatch) => {
    try {
        const response = await apiService.get(`auth_licences`);

        dispatch({
            type: UPSERT_AUTHLICENSES,
            payload: response.data.value,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

export const getLicensesWithRedirect = (navigate, cntxt) => async (dispatch) => {
    try {
        const response = await apiService.get(`auth_licences`);

        dispatch({
            type: UPSERT_AUTHLICENSES,
            payload: response.data.value,
        });

        //als de gebruiker alleen aan 1 licentie gekoppeld is, redirect naar de Appsportal pagina.
        if (Object.keys(response.data.value).length === 1) {
            sessionStorage.setItem("app_user_license", response.data.value[0].licensesid);

            dispatch(handleLicenseLogin(response.data.value[0].licensesid, navigate, cntxt));
        }
        //als de gebruiker aan meerdere licentie is gekoopeld, redirect naar de Keuzelicentie pagina.
        else {
            if (window.location.pathname !== "/selection/licenses") {
                // als de default licentie is al gevuld vanuit de url deze stap ook overslaan en direct op de licentie inloggen anders redirect naar d keuzelicentie pagina
                if (sessionStorage.getItem("app_user_license")) {
                    dispatch(handleLicenseLogin(Number(sessionStorage.getItem("app_user_license")), navigate, cntxt));
                } else {
                    navigate("/selection/licenses");
                }
            }
        }
    } catch (error) {
        // Indien een gebruiker is gemaakt maar niet aan een versie is gekoppeld
        if (error.response && error.response.data.error.code === "-310025") {
            dispatch({
                type: ERROR,
                payload: t("error.noLicense"),
            });
        } else {
            // toon foutmelding
            dispatch(handleErrors(error));
        }
    }
};

// License login
export const handleLicenseLogin = (id, navigate, cntxt) => async (dispatch) => {
    try {
        const response = await apiService.post(`loginlicense`, { licensesid: id });
        //verwijderd de oude token en sla de nieuwe token op
        sessionStorage.setItem("default_oauth", response.data.value);
        // check of de default app aanwezig is en zo niet redirect naar de apps portal
        if (sessionStorage.getItem("default_app_code") !== null) {
            // vraag alle apps
            const apps = await dispatch(getApps(navigate, 1));
            const defaultApp = apps?.find((app) => app.code === sessionStorage.getItem("default_app_code"));

            if (apps && defaultApp) {
                // vraag alle versies van de geselecteerde app
                dispatch(getLicenseVersions(defaultApp.defappsid, defaultApp.code, navigate, cntxt));
            } else {
                // niet aan de geselecteerde app gekoppeld
                dispatch({
                    type: ERROR,
                    payload: `${t(`error.${-310018}`)}: ${sessionStorage.getItem("default_app_code")}`,
                });
            }
        } else {
            // redirect naar apps portal indien navigate is niet null
            if (navigate !== undefined) {
                navigate("/selection/portal/apps");
            }
        }
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};
